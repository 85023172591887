.tooltip {

  border-bottom: 2px dotted #0087b9;

}


.tooltiptext {

  visibility: hidden;

  opacity: 0;

  position: absolute;

  z-index: 1000;

  background-color: #0087b9;

  color: white;

  text-align: center;

  border-radius: 10px;

  padding: 10px;

  bottom: calc(100% + 4px);

  transition: opacity 0.3s;

  width: 250px;

  margin-left: -230px;

  box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.1);

}

.tooltiptext::after {

  content: "";

  position: absolute;

  top: 100%;

  right: 50px;

  margin-left: -6px;

  border-width: 6px;

  border-style: solid;

  border-color: #0087b9 transparent transparent transparent;

}


.tooltip:hover + .tooltiptext {

  visibility: visible;

  opacity: 1;

}
