@import '../../styles/_var.scss';

$top-line-height: 2.5vh;
$left-offset:15em;
$offset:2em;
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#canvas {
    padding: 0px;
    margin: 0px;
    border: 0px;
    position: absolute;
    top: 90px;
    right: 0px;
    left: $left-offset;
    bottom: 0px;
    overflow: scroll;
    background-color: #ffffff;
    width: 10000px;
    height: 10000px;
}


#toolbar {
    position: fixed;
    background-color: #eeeeee;
    margin: 0px;
    top: $top-line-height;
    left: $left-offset;
    right: 0px;
    overflow: hidden !important;
    z-index: 998 !important;
    border: 0 none;
    border-bottom: 1px solid #d6d6d6;
}

#toolbar_hint {
    position: absolute;
    top: 19px;
    height: 64px;
    right: 40px;
    color: white;
}

#side-nav {
    border-right: $side-border-right solid $bright-color;
    bottom: 0;
    background: #fff;
    color: #333;
    display: block;
    font-family: "RobotoDraft", sans-serif;
    left: 0;
    position: fixed;
    top: calc(#{$tab-menu-height} + #{$top-line-height});
    width: 15em;
    z-index: 997;
    resize: horizontal;
}

#edit-panel {
    padding-top: 89px;
    border-left: 2px solid #85bdbe;
    bottom: 0;
    background: #fff;
    color: #333;
    display: block;
    font-family: "RobotoDraft", sans-serif;
    right: 0;
    position: fixed;
    top: 0;
    width: 400px;
    z-index: 5;
    transition: 1s;
    overflow-y: scroll;
    overflow-x: scroll;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #85bdbe;
        border: 1px solid #e0e0e0;
        height: 50px;
    }
    .p-multiselect {
        width: 150px;
    }
}

#side-nav #logo {
    border-bottom: 1px solid #e0e0e0;
    display: block;
    height: 64px;
    margin: auto;
    padding: 12px 24px 10px 24px;
    position: relative;
    font-size: 30px;
    font-weight: 100;
}


.process-zoom {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

h2 {
    color: inherit;
}

.topLigne {
    height: $top-line-height;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #85bdbe;
    z-index: 998 !important;
}

.delete {
    position: absolute;
    right: 15px;
    :hover {
        cursor: pointer;
    }
    i {
        font-size: 22px;
        color: darkred;
    }
}

.timeManipulation {
    i {
        margin-left: 15px;
        font-size: 20px;
    }
    i:hover {
        cursor: pointer;
    }
}

.name-field {
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    height: 150px;
    input {
        margin-left: 10px;
        width: 150px;
    }
}

.node-question-result {
    .draw2d_shape_basic_Circle{
        background: url("https://image.flaticon.com/icons/svg/60/60993.svg") 0 0 / 20px 30px repeat;
    }
}

.node-configuration {
    margin-left: 5px;

    h3, h5 {
        margin-bottom: 10px;
    }

    .field-radiobutton {
        margin: 10px;
    }

    .p-dropdown {
        margin: 10px;
    }

    table {

        margin-right: 5px;

        thead {
            background-color: lightgrey;
        }

        td, th {
            border: darkgrey 1px solid;
            height: 10px;
        }
    }
}

.newTreeForm {
    display: block;
    input {
        width: 60%;
        margin-bottom: 10px;
    }
    label {
        width: 120px;
        display:inline-block
    }
}

.dropdownQuestions {
    .p-dropdown-panel {
        width: 200px;
    }
    li {
        text-overflow: ellipsis;
    }

    li:hover {
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
    }
}

.nodeRangeTable {
    input {
        width: 100%;
        border-radius: 0px;
        margin: 0;
    }
    td {
        padding: 0px !important;
    }

    tr:hover {
        background-color: white !important;
    }

    li {
        text-align: left;
    }

    .p-dropdown, .p-multiselect {
        width: 100%;
        margin: 0;
        padding: 0px !important;
        border: 0px;
        border-radius: 0px;
    }
}

.nodeMinMaxTable {
    table{
        width: 98%;
        tbody {
            max-height: 50px;
        }
    }
    padding-bottom: 20px;
}

#node-min-decision-tree {
    width: 98% !important;
}

.nodeComputation {
    label {
        width: 75px;
        display: inline-block;
    }
    .p-dropdown {
        width: 175px;
    }
    .number {
        width: 175px;
        margin: 10px;
    }

}

.field-checkbox {
    padding-bottom: 10px;
    label {
        margin-left: 10px;
    }
}

.addValueButton {
    display: flex;
    justify-content: center;
    button {
        margin: 20px;
    }
}

.nodeConcatTable td {
    max-width: 200px;
}

.nodeConcatTableDiv {
    width: 250px;
}

.informationsBox {
    border: solid 2px #d96666;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    font-size: 12px;
}

.menuList {
    width: 100% !important;
}
