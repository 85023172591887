.bbri-theme {

  .table-results-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .table-results-actions {
      display: flex;
    }
  }

  #table-results {
    tbody {
      tr {
        &:hover {
          background-color: transparent;
        }

        & > td > ul {
          padding-left: revert;
        }
      }
    }
  }

  // Mobile & Tablet
  @media (max-width: 420px) {
    .containerSummary {
    }
    .containerSummary .table div {
      padding-right: 0px !important;
      padding-top: 0px !important;
      padding-left: 0px !important;
    }
  }
}
