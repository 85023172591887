@import "../../styles/var";

$matrix-height: 150px;

.matrix-container {
  .matrix-wrapper {
    .matrix-label {
      cursor: pointer;

      background-repeat: no-repeat;
      background-color: #f1f1f1;
      background-size: 50%;
      background-position: 50% ($matrix-height/2.5);
      display: flex;
      align-items: top;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid $primary-color;
      height: $matrix-height;
      padding: 10px 0 10px 0;
      color: #585858;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.45rem;
      font-weight: bold;
    }

    &.matrix-selected {
      .matrix-label {
        border: 1px solid $selected-border-color;
        background-color: white;
      }
    }
  }
}

// Mobile & Tablet
@media (max-width: 768px) {
  .matrix-container {
    .matrix-wrapper {
      .matrix-label {
        font-size: 0.8rem;
        background-size: 90%;
        background-position: 50% ($matrix-height/2.4);
      }
    }
  }
}

// Big tablet & Small screen
@media (min-width: 769px) and (max-width: 991px) {
  .matrix-container {
    .matrix-wrapper {
      .matrix-label {
        font-size: 1.1rem;
        background-size: 90%;
        background-position: 40% ($matrix-height/2.2);
      }
    }
  }
}

// Huge tablet & medium screen
@media (min-width: 992px) and (max-width: 1200px) {
  .matrix-container {
    .matrix-wrapper {
      .matrix-label {
        font-size: 1.25rem;
        background-size: 70%;
        background-position: 50% ($matrix-height/2.5);
      }
    }
  }
}

// Mobile & Tablet
@media (max-width: 360px) {
  .matrix-container {
    .matrix-wrapper {
      width: 100% !important;
    }
  }
}

// Mobile & Tablet
@media (max-width: 360px) and (min-width: 290px) {
  .matrix-container {
    .matrix-wrapper {
      .matrix-label {
        background-size: 70%;
      }
    }
  }
}
