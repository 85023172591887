.bbri-theme {
  .feedback-content {
    margin-bottom: 10px;
  }

  .feedback-screenshot {
    display: flex;
    align-items: center;

    button {
      margin-right: 10px;
    }
  }

  .feedback-comment {
    margin-bottom: 10px;

    label {
      font-weight: bold;
      margin-left: 5px;
    }

    textarea {
      margin-top: 0;
    }
  }

  .feedback-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #0087b7;
    color: white;
    padding: 7px 8px;
    border-radius: 50%;
    border: none;
    z-index: 1;
    cursor: pointer;

    &.feedback-open {
      border-radius: 0% 50% 50% 50%;
    }

    &:hover {
      background-color: lighten(#0087b7, 5%);
    }
  }
}
