$text-color: #4e636d;
$border-color: #005979;
$primary-color: #0087b7;
$button-background-color: #ec6607;;
$button-background-color-hover: #ef7d2c;;
$danger-color: #f30c0c;

$body-background-color: #eee;

// = Selection
$selected-border-color: #eb964c;
$selected-background-color: #e3f1f1;


/* Logo colors */
$bright-color: #A2D9DB;
$medium-color: #01A398;
$dark-color: #01242D;

$top-line-height: 2.5vh;


$side-border-right: 7px;

$tab-menu-height : 5%;

$special-font-weight: 600;

