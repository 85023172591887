.form-input-group {
  display: flex;
  align-items: center;

  .form-input-unit {
    padding: 10px;
  }
}

.form-actions-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.right-actions {
  display: flex;
  justify-content: space-between;
}

button > * {
  pointer-events: none;
}
