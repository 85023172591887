@import "../../styles/var";

$stepper-secondary-color: #0087b7;
$stepper-active-color: #005979;
$stepper-current-color: #eb964c;
$stepper-height: 120px;
$mobile-stepper-height: 30px;
$vertical-stepper-height: 250px;

.form-stepper-wrapper {
  height: $stepper-height;
  width: 100%;

  .form-step-list {
    display: flex;
    height: 100%;
    list-style-type: none;

    li {
      position: relative;
      width: 100%;

      .form-step-title {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        width: $stepper-height;
        transform: rotate(-40deg) translate(-105%);
        transform-origin: top left;
        top: 14px;
        left: 40%;
        font-size: 0.8rem;
        line-height: 0.8rem;
        height: 1.6rem;
        z-index: 1;
      }

      // = Line
      &::before {
        position: absolute;
        content: "";
        top: 13px;
        left: -50%;
        height: 2px;
        width: 100%;
        background-color: $stepper-secondary-color;
        z-index: -1;
      }

      // = Circle
      .form-step-circle {
        display: block;
        position: absolute;
        left: 45%;
        border-radius: 50%;
        content: " ";
        height: 28px;
        width: 28px;
        background-color: $body-background-color;
        border: 2px dashed $stepper-secondary-color;

        &.form-step-circle-clickable {
          cursor: pointer;
        }
      }

      &:first-child::before {
        content: none;
      }

      // = Circle: Summary
      &.form-step-summary .form-step-circle {
        border-color: $stepper-active-color;
        border-style: solid;
      }

      // = Circle: Active step
      &.form-step-active .form-step-circle {
        background-color: $stepper-secondary-color;
        border-color: $stepper-active-color;
        border-style: solid;
      }

      // = Circle: Current step
      &.form-step-current .form-step-circle {
        background-color: $body-background-color;
        border-color: $stepper-current-color;
        border-style: solid;
      }

      // = Line: Active, Current step & Summary
      &.form-step-summary::before,
      &.form-step-current::before,
      &.form-step-active::before {
        height: 3px;
        background-color: $stepper-active-color;
      }
    }
  }
}

// Mobile & Tablet
@media (max-width: 768px) {
  // == STEPPER CLASSIC
  .form-stepper-wrapper:not(.vertical-form-stepper) {
    height: $mobile-stepper-height;

    .form-stepper {
      cursor: pointer;

      .form-step-list {
        li {
          // = Line
          &::before {
            top: 7px;
            height: 1px;
          }

          // = Circle
          .form-step-circle {
            left: 25%;
            height: 15px;
            width: 15px;
          }

          .form-step-title {
            display: none;
          }

          // = Line: Active & Current step
          &.form-step-current::before,
          &.form-step-active::before {
            height: 2px;
          }
        }
      }
    }
  }

  // == VERTICAL STEPPER
  .form-stepper-wrapper.vertical-form-stepper {
    position: relative;
    height: $vertical-stepper-height;

    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));

    .form-stepper {
      height: $vertical-stepper-height;
      overflow: auto;

      .form-step-list {
        height: auto;
        flex-direction: column;
        margin-bottom: 15px;

        li {
          display: flex;
          align-items: center;
          height: 50px;

          // = Step title
          .form-step-title {
            position: static;
            transform: none;
            text-align: left;
            justify-content: flex-start;
            margin-left: 35px;
            width: 100%;
          }

          // = Line
          &::before {
            height: 100%;
            width: 2px;
            left: 13px;
            top: -50%;
          }

          // = Circle
          .form-step-circle {
            left: 0;
          }

          &.form-step-active {
            cursor: pointer;
          }
        }
      }
    }

    .vertical-form-step-close {
      position: absolute;
      cursor: pointer;
      top: 5px;
      right: 10px;
      border: none;
      background-color: white;
      border-radius: 50%;
      padding: 2px 2px 0 2px;
      z-index: 1;
    }
  }
}

.confirm-button {
  margin: 2px;
  bottom: 10px;
  right: 10px;
  background-color: #0087b7;
  color: white;
  padding: 10px 10px;
  border-radius: 10%;
  border: none;
  z-index: 1;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: lighten(#0087b7, 5%);
  }
}
