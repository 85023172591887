@import '../../../../styles/_var.scss';





.question-selector{
    width: 100%;
    text-align: center;
    bottom: 0;
    background: #fff;
    color: #333;
    display: block;
    font-family: "RobotoDraft", sans-serif;
    position: fixed;
    z-index: 998;


    .p-dropdown.p-component.p-inputwrapper{
        width: 80%;
        margin: 1%;
    }

    .p-dropdown-panel{
        /* left: -155.85px !important;
        transition: left 0.5s cubic-bezier(0.4, 0, 1, 1);
        overflow: auto; */
       /*  margin-right: 40px !important; */
        width: 100px !important;
        left: 0 !important;
    }

    .p-dropdown {
        margin: 10px;
    }



    .p-datatable .p-datatable-tbody > tr > td {
        text-align: center;
        border: 1px solid #e9ecef;
        border-width: 0 0 1px 0;
        padding: 0.1rem 1px;
        align-content: center;
    
        width: fit-content;
        font-size: 1.7vh;
    
        /* overflow: hidden; */
        overflow: auto;
        white-space: nowrap;
        max-width: 100%;
        box-sizing: content-box;
        /* transform: translateX(0);
        transition: 1s; */
    
        /* &::-webkit-scrollbar {
          display: none;
        } */
    
        &::-webkit-scrollbar {
          /* display: block;
          visibility: visible; */
          height: 0;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    
      .p-datatable .p-datatable-tbody > tr > td:hover {
    
    
        &::-webkit-scrollbar {
          /* display: block;
          visibility: visible; */
          height: 5px;
        }
      }
    
    
      .p-datatable-tbody > tr > .p-selection-column{
        text-align: center !important;
      }
      .p-datatable .p-datatable-thead > tr > th{
        padding:  5px;
        text-align: center;
      }


      .p-datatable.p-component.p-datatable-scrollable.p-datatable-hoverable-rows{
        height: 100%;
        box-sizing: content-box;

        
        .p-datatable-scrollable-wrapper{
          height: 100%;
          .p-datatable-scrollable-view{
            height: 100%;
            .p-datatable-scrollable-header{
              height: fit-content;
            }
    
            .p-datatable-scrollable-body{
              height: fit-content !important;
              overflow-y: scroll !important;
              visibility: visible !important;
              max-height: 87.5% !important;
            }
          }
        }
      }


        
    .p-datatable .p-datatable-tbody > tr.p-highlight { 
        background: $bright-color; 
        /* background: $dark-color; */
        color: $dark-color; 
        transition: font-size 0.2s cubic-bezier(0.4, 0, 1, 1), background 0.2s cubic-bezier(0.4, 0, 1, 1); 
        font-weight: $special-font-weight;
    }
    
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: $bright-color;/* #FEC418; */
        border-color: #6c757d;
        color: #6c757d;
    }
    
    
    .p-datatable .p-sortable-column.p-highlight {
        background: #f8f9fa;
        color: $dark-color;
    }
    
    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: $dark-color;
    }
}

.scriptFilter th.p-filter-column{
  padding: 0px !important;
  margin: 0px !important;
}

