.bbri-theme {
  .CookieConsent {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    background-color: $primary-color;
    color: white;
    z-index: 999;

    .cookie-consent-button {
      background-color: $button-background-color;
      color: white;
      border-radius: 20px;
      font-size: 1rem;
      padding: 5px 15px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: $button-background-color-hover;
      }
    }
  }
}

// = Mobile
@media (max-width: 576px) {
  .cookie-consent-content {
    font-size: 0.8rem;
  }

  .cookie-consent-button {
    font-size: 0.8rem !important;
  }
}

// = Mobile
@media (max-width: 480px) {
  .CookieConsent {
    height: 100px !important;
  }
}
