@import "../../styles/var";

.image-wrapper {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 3px;
  max-width: 300px;
  cursor: pointer;
  padding: 10px;
  position: relative;

  img {
    width: 100%;
    max-height: 200px;
  }
}

.image-wrapper-zoom {
  display: block;
  max-width: 100%;
  min-width: 10%;
  margin: 0 auto;
  overflow: auto;
  overflow-x: scroll;
}

.image-wrapper img {
  display: block;
}

.image-wrapper .zoom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.center {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 20px;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
}
.center button {
  margin-left: 10px;
  background-color: #e98b3a;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  padding: 5px 15px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f7931d !important;
  }
}

.Modal-module_body__1klV2 {
  overflow-x: scroll !important;
}
