$orange-color: #eb964c;

.save-button {
  float: right;
}
.schemaName {
  float: left;
}

.edit {
  margin-left: 15px;
  margin-right: 15px;
  :hover {
    cursor: pointer;
  }
}

.action {
  .save {
    margin-left: 15px;
  }
  .p-button-danger {
    margin-left: 15px;
  }
  .p-button-help {
    margin-left: 2px;
  }
  .p-button-warning {
    margin-left: 2px;
  }
  .p-button-success {
    margin-left: 2px;
  }
}

#json {
  height: 400px;
  background-color: #ebebeb;
  color: black;
  border: solid 1px lightgrey;
  border-radius: 5px;
  padding: 10px;
  overflow: scroll;
  background-image: repeating-linear-gradient(0deg, #dedede, #dedede 1px, transparent 1px, transparent 15px),repeating-linear-gradient(-90deg, #dedede, #dedede 1px, transparent 1px, transparent 15px);
}

.toolbar, .p-toolbar {
  width: 100%;
  height: 73px;
}
