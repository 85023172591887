@import "../../styles/var";

.diagram-container {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.dot-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    
}

.dot {
    display: flex;
    cursor: pointer;
    background-color: $medium-color;
    color: white;
    justify-content: center;
    align-items: center;
    max-height: 30px;
    max-width: 30px;
    border: 1px solid $dark-color;
    border-radius: 50%;
    margin-right: 5px;
    font-size: smaller;
}

.selected-dot {
    @extend .dot;
    background-color: $bright-color;
    color: black;
}

.comment-container {
    max-height: 500px;
    overflow: scroll;
}

.comment-editor-container, .legend-editor-container {
    padding: 10px;
}

.legend-container {
    display: flex;
    flex-wrap: wrap;
    div {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-right: 10px;
    }
}

.legend-editor-container div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.comment-editor-container label {
    display: inline-block;
    width: 300px;
}