@import "../../styles/var";

.radio-image-container {
  width: 100%;

  .radio-image-wrapper {
    cursor: pointer;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    border: 1px solid $border-color;

    display: flex;
    align-items: center;

    .radio-image {
      height: 100px;

      img {
        height: 100px;
      }
    }

    .radio-image-label {
      padding: 10px;
      font-weight: bold;
    }

    &.radio-image-selected {
      border: 2px solid $selected-border-color;
      background-color: $selected-background-color;
    }
  }

  .radio-image-wrapper + .radio-image-wrapper {
    margin-top: 10px;
  }
}

// Mobile & Tablet
@media (max-width: 768px) {
  .radio-image-container {
    .radio-image-wrapper {
      .radio-image {
        height: 75px;

        img {
          height: 75px;
        }
      }

      .radio-image-label {
        font-size: 0.8rem;
      }
    }
  }
}
