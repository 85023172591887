@import "../../styles/var";

.inputNumberForm {
  input[type=number] {
    width: 100px;
    border-radius: 3px 0px 0px 3px;
    border: 1px solid $border-color;
    border-right-color: rgb(147, 170, 178);
    font-size: 16px;
    padding: 13px 18px;
    color: #4f636d;
    outline: none;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }

  input[type=number]:hover::-webkit-inner-spin-button {
    width: 14px;
    height: 30px;
  }

}

.form-input-group {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
}
