.login-wrapper {
  height: 100vh;

  .logo {
    text-align: center;
    max-height: 80px;
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: -19px;
  }

  .login-card {
    background-color: white;
    padding: 20px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);

    .login-logo-wrapper {
      position: relative;
      text-align: center;
      margin-bottom: 20px;

      .tool-logo {
        max-height: 100px;
      }

      [class^="Select-module_select__"] {
        position: absolute;
        top: 0;
        right: 0;
        // width: 30px;
      }
    }

    .login-content {
      input {
        width: 100%;
        max-width: 100%;
        margin-top: 0;
      }
    }
  }
}

.login-text {
  text-align: justify;
  text-justify: inter-word;
}

.login-header {
  color: #0087b7;
}

.partners {
  img {
    max-width: 100%;
  }
}

// = Mobile
@media (max-width: 576px) {
  .login-wrapper {
    .login-card {
      .login-logo-wrapper {
        [class^="Select-module_select__"] {
          position: static;
          float: right;
        }
      }
    }
  }
}
