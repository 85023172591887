@import '../style.scss';


$icon-size-values : 3.1rem;

.add-tree {
  height: 80px;
  border-top: 1px solid #e0e0e0;
}

.vertical-center {
  text-align: center;
}

.palette_node_element {
  cursor: move;
}

.drag-elements {
  border-top: 1px solid #e0e0e0;

  h2 {
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
  }

  img {
    //padding-left: 10px;
  }
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  float: left;
  border: 3px solid rgba(0, 0, 0, 0.25);
  transition: 0.25s border;
  img {
    width: 40px;
    height: 40px;
  }
}

.circle:hover {
  border: 25px solid rgba(0, 0, 0, 0.25);
}

.square {
  border-radius: 0 !important;
}

.menuList {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  li, a {
    height: 30px;
  }
  li span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.confirmation-content {
  display: flex;
  align-items: center;
}

.menuFilter {
  width: 100%;
}

.nodesList {
  display: grid;
}

.decisionTreeListTitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  width:100%;
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}
