.questionList {
  height: 600px !important;
  overflow-y: hidden;
  overflow-x: hidden;
  .p-selectable-row {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px !important;
    }
  }
  .hide {
  }
  thead {
  }
  .p-datatable-scrollable-body {
    overflow-x: hidden !important;
  }
}

.addQuestion {
  text-align: center;
  justify-content: center;
  height: 10vh;
  align-items: center;
  div {
    margin-top: 10px;
  }

  span {
    font-size: 15px;
  }

  border-top: solid 3px #a2d9db;

}

#addQuestionDialog {
  label {
    width: 100px;
    display: inline-block;
  }
  .p-dialog-content div{
    margin: 2px;
  }
}

.formConfigModal {
  div {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
  label {
    display: inline-block;
    width: 100px;
  }
  .p-inputtext {
    width: 600px;
  }
}

.p-datatable-dragpoint-bottom {
  border-bottom: solid 6px #e98b3a;
}

.questionList .p-table-reorderablerow-handle:hover {
  cursor: move;
}

.addQuestion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
