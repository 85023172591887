.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 64px;
  box-shadow: 0px 1px 3px #646464;
  padding: 0 10px;

  .logo, .tool-logo {
    height: 50px;
    max-height: 50px;
  }
}

.layout-header .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-compact {
  display: none;
}

// = Mobile
@media (max-width: 576px) {
  .layout-header {

    .logo, .tool-logo {
      max-height: 35px;
    }

    .logo {
      margin-right: 5px;
    }

    .logo-compact {
      display: block;
    }

    .logo-full {
      display: none;
    }

  }

  .hidden-sm {
    display: none;
  }
}
